<template>
  <v-dialog overlay-opacity="0.6" v-model="dialog" @keydown.esc="close()" max-width="500" scrollable>
    <v-card>
      <v-toolbar :color="getHeaderColor()" dense flat>
        <v-toolbar-title class="text-truncate pr-5">{{ $vuetify.lang.t(`$vuetify.protectedAreas.title`) }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pl-2 py-1 textDense">
        <div class="py-2">{{ $vuetify.lang.t(`$vuetify.protectedAreas.intro`) }}</div>
        <ul>
          <li>
            <span>{{ $vuetify.lang.t(`$vuetify.protectedAreas.info`) }}: </span>
            <span class="font-weight-bold"><a target="_blank" :href="url">{{ name }}</a></span>
            
          </li>
          <li>
            {{ $vuetify.lang.t(`$vuetify.protectedAreas.org`) }}: {{ organisation }}
          </li>
        </ul>
        <div class="pt-2 font-weight-bold">{{ $vuetify.lang.t(`$vuetify.protectedAreas.states.S${prot}`) }}</div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'

export default {
  name: 'protected-area-dialog',
  mixins: [utilityMixin],
  data: () => ({
    dialog: false,
    feature: null,
    name: null,
    url: null,
    prot: null,
    organisation: null,
  }),
  methods: {
    open(feature) {
      this.name = feature.get('name')
      this.url = feature.get('url')
      this.prot = feature.get('prot_id')    
      this.organisation = feature.get('org_name')
      var relUrl = this.$vuetify.lang.current=='de'?'de/schutzgebiet/uuid/':'en/protected_area/uuid/'
      this.url = this.url.replace('rules/show_protectedarea/', relUrl)     
      this.dialog = true
    },
    close() {
      this.dialog = false
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-btn {
  background-color: var(--navigationColor);
  color: white !important;
  border-radius: 0%;
  margin-left: -16px !important;
  margin-right: -16px !important;
}
</style>
