<template>
  <div>
    <v-toolbar flat :color="headerColor()" :height="myHeight">
      <v-btn v-if="showBackButton" :style="{ height: myHeight + 'px' }" @click="navigateBackward" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <a v-else-if="displayLongIcon()" @click="refreshPage()" target="_blank">
        <v-icon
          :size="myHeight * 6"
          foregroundColor="#FFFFFF"
          :backgroundColor="getPrimaryColor()"
          id="iconSkitourenguru"
          >$logoLabeled</v-icon
        >
      </a>
      <a v-else @click="refreshPage()" target="_blank">
        <v-icon
          :size="(myHeight * 9) / 8"
          foregroundColor="#FFFFFF"
          :backgroundColor="getPrimaryColor()"
          id="iconSkitourenguru"
          >$logoStandard</v-icon
        >
      </a>

      <v-spacer @click="displayHelp()" class="fingerPointer" :style="{ height: myHeight + 'px' }"></v-spacer>

      <v-toolbar-title @click="displayHelp()" style="color: #333333;" class="px-6 fingerPointer">
        <span>{{ title }}</span>
        <!--         
        <span v-if="status != 1"> (</span>
        <span v-if="status != 1" :style="{ color: getWarningColor() }">{{
          $vuetify.lang.t(`$vuetify.regionStatus.${status}.medium`)
        }}</span>
        <span v-if="status != 1">)</span> 
-->
      </v-toolbar-title>

      <v-spacer @click="displayHelp()" class="fingerPointer" :style="{ height: myHeight + 'px' }"></v-spacer>

      <!-- dropdown menu -->
      <v-menu classtransition="slide-y-transition" style="z-index: 200;">
        <template v-slot:activator="{ on }">
          <v-btn :style="{ height: myHeight + 'px' }" icon v-on="on">
            <icon-menu :width="(myHeight * 30) / 24" :height="myHeight" :nrNews="getNumberOfUnreadNews()"
              >$iconMenu</icon-menu
            >
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="group" active-class="text--accent-4">
            <v-list-item>
              <v-list-item-title class="font-weight-medium" @click="openLangDialog()">{{
                $vuetify.lang.t(`$vuetify.menu.language`)
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="openNewsView()"
                >{{ $vuetify.lang.t(`$vuetify.menu.news`) }}
                <span v-if="getNumberOfUnreadNews() > 0">
                  <span>(</span>
                  <span :style="{ color: getWarningColor() }">{{ getNumberOfUnreadNews() }}</span>
                  <span>)</span>
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="openDrawRoute()" target="_blank">
              <v-list-item-title>{{ $vuetify.lang.t(`$vuetify.menu.drawRoute`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :href="getManualLink()" target="_blank">
              <v-list-item-title>{{ $vuetify.lang.t(`$vuetify.menu.manual`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="openNewsView(30)">{{ $vuetify.lang.t(`$vuetify.menu.video`) }}</v-list-item-title>
            </v-list-item>            
            <v-list-item :href="getHomeLink()" target="_blank">
              <v-list-item-title>{{ $vuetify.lang.t(`$vuetify.menu.info`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :href="getMediaLink()" target="_blank">
              <v-list-item-title>{{ $vuetify.lang.t(`$vuetify.menu.media`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :href="$vuetify.lang.t(`$vuetify.intro.supported.partners.bfu.link`)" target="_blank">
              <v-list-item-title>{{ $vuetify.lang.t(`$vuetify.menu.prevention`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openDisclaimerDialog()">
              <v-list-item-title>{{ $vuetify.lang.t(`$vuetify.disclaimer.title`) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="displayAbout()" target="_blank">
              <v-list-item-title>{{ $vuetify.lang.t(`$vuetify.menu.about`) }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>
    <disclaimer-dialog ref="disclaimer" style="z-index: 200;"></disclaimer-dialog>
    <lang-dialog ref="langDialog" style="z-index: 200;"></lang-dialog>
  </div>
</template>

<script>
import DisclaimerDialog from './DisclaimerDialog.vue'
import LangDialog from './LangDialog.vue'
import store from '@/store/store'
import { headerHeight } from '../utilities'
import { utilityMixin } from '../mixins/utilityMixin'
import { getRiskCssColor, getRegionCssColor } from '../colors'
import { getIsMobile, getInfoServerLink } from '../utilities'
import IconMenu from '@/icons/IconMenu.vue'

export default {
  name: 'app-header',
  mixins: [utilityMixin],
  components: {
    LangDialog,
    DisclaimerDialog,
    IconMenu,
  },
  data: () => ({
    drawer: false,
    group: null,
    myHeight: headerHeight,
  }),
  computed: {
    showBackButton: function () {
      return this.$route.name !== 'regions-view'
    },
    title: function () {
      return store.state.header.title
    },
    status: function () {
      return store.state.header.status
    },
    risk: function () {
      return store.state.header.risk
    },
  },
  methods: {
    headerColor() {
      if (this.risk != null) {
        return getRiskCssColor(Number(this.risk), 'HEADER')
      }
      return getRegionCssColor(this.status, 'HEADER')
    },
    getNumberOfUnreadNews() {
      let numberOfUnread = 0
      const items = store.state.news.items
      const newsIds = items.map((item) => item.id)
      for (const id of newsIds) {
        if (!store.state.news.readIds.includes(id)) {
          numberOfUnread++
        }
      }
      return Math.min(numberOfUnread, 9)
    },
    openLangDialog() {
      this.$refs.langDialog.open()
    },
    openNewsView(id) {
      if (typeof id == 'undefined' || id == null) {
         this.dispose()
         this.$router.push({ name: 'news-view' })
         return
      }
      this.dispose()
      this.$router.push({
        name: 'news-view',
        query: { id: id.toString() }
      })
    },
    openDrawRoute() {
      this.dispose()
      this.$router.push({ name: 'rating-view', })
    },
    openDisclaimerDialog() {
      this.$refs.disclaimer.forceOpen(this.rejectDisclaimer, null)
    },
    rejectDisclaimer() {
      if (this.$route.name != 'regions-view') {
        this.dispose()
        this.$router.push({
          name: 'regions-view',
        })
      }
    },
    navigateBackward() {
      const currentRoute = this.$route.name
      if ((currentRoute === 'area-view') || (currentRoute === 'news-view')) {
        this.dispose()
        this.$router.push({ name: 'regions-view' })
        this.currentView = 'regions-view'
        return
      }
      if (currentRoute == 'track-view') {
        var area = this.$route.query.area
        this.dispose()
        this.$router.push({ name: 'area-view', query: { area: area } })
        this.currentView = 'area-view'
        return
      }
      // if (currentRoute == 'rating-view') {
      this.dispose()
        this.currentView = 'regions-view'       
      if (history.length == 0) {
        this.$router.push({ name: 'regions-view' })         
      } else {
        history.back()
      }
    },
    getHomeLink() {
      return getInfoServerLink()
    },
    getManualLink() {
      return 'calc_data2/doc/Intro_' + this.$vuetify.lang.current + '.pdf'
    },
    getVideoLink() {
      return getInfoServerLink() + '/index.php/services/221-videos'
    },
    getMediaLink() {
      return getInfoServerLink() + '/index.php/mymedia'
    },
    getAboutLink() {
      return getInfoServerLink() + '/index.php/about'
    },
    displayLongIcon() {
      return !getIsMobile()
    },
    displayHelp() {
      // eslint-disable-next-line no-console
      console.log('AppHeader: Display help')
      var tag = this.status == 1 && this.risk != null ? 'riskIndicator' : 'status'
      this.$emit('displayHelp', tag)
    },
    displayAbout() {
      this.$emit('displayHelp', 'about')
    },
    refreshPage() {
      location.reload()
    },
    dispose() {
      this.$emit('dispose')
    } 
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#iconSkitourenguru {
  margin-left: -16px;
}

.v-btn {
  background-color: var(--navigationColor);
  color: white !important;
  border-radius: 0%;
  margin-left: -16px !important;
  margin-right: -16px !important;
}
</style>
